import * as Dialog from '@radix-ui/react-dialog';


interface AlertDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  message: string;
  messageElement?: JSX.Element;
  action: () => void;
}

const AlertDialog = (props: AlertDialogProps) => {

  return (
    <Dialog.Root open={props.open} onOpenChange={props.setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-mobileMask md:bg-desktopMask bg-opacity-50 md:bg-opacity-40 z-[70]" />
        <Dialog.Content className="fixed bottom-0 md:bottom-auto md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bg-mooimom px-20 py-12 rounded-t-lg md:rounded-lg w-full md:w-fit md:max-w-1/2 md:min-w-[400px] mx-auto z-[75] outline-none focus:ring-transparent">
          <Dialog.Title />
          {props.messageElement !== null && props.messageElement !== undefined ? (
            <>
              <Dialog.Description />
              {props.messageElement}
            </>
          ) : (
            <Dialog.Description className="text-sm font-semibold text-center text-white font-poppins">{props.message}</Dialog.Description>
          )}
          <div className="flex mt-[18px] justify-center space-x-2">
            <div
              className="w-20 p-2 text-sm text-center bg-white rounded-lg cursor-pointer text-mooimom font-poppins"
              onClick={() => props.action()}
            >Có</div>
            <div
              className="w-20 p-2 text-sm text-center text-white rounded-lg cursor-pointer bg-attributeBorder font-poppins"
              onClick={() => props.setOpen(false)}
            >Không</div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default AlertDialog;
