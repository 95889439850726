import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { CartsState, Carts, initCarts } from "./initialState";

const hashCode = process.env.NEXT_PUBLIC_HASH_CODE;
if (!hashCode) {
  throw new Error("NEXT_PUBLIC_HASH_CODE environment variable is not set");
}

// Function to encrypt carts information
const encryptCarts = (carts: Carts) => {
  return CryptoJS.AES.encrypt(JSON.stringify(carts), hashCode).toString();
};

// Function to decrypt carts information
const decryptCarts = (encryptedCarts: string) => {
  try {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedCarts, hashCode).toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Failed to decrypt carts information", error);
    return initCarts;
  }
};

export const useCartsStore = create<CartsState>()(
  persist<CartsState>(
    (set, get) => ({
      carts: encryptCarts(initCarts),
      updateCarts: (carts: Carts) => {
        set(
          produce((draft: CartsState) => {
            draft.carts = encryptCarts(carts);
          })
        );
      },
      getCarts: () => {
        const state = get();

        if (typeof state.carts === "object") {
          return state.carts;
        } else if (typeof state.carts === "string") {
          return decryptCarts(state.carts);
        } else {
          return initCarts;
        }
      }
    }), {
      name: "carts"
    } as PersistOptions<CartsState>
  )
);
