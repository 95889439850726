import * as Dialog from '@radix-ui/react-dialog';


interface AlertDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  message: string;
  tittle?: string;
  action?: () => void;
}

const AlertDialog = (props: AlertDialogProps) => {

  return (
    <Dialog.Root open={props.open} onOpenChange={props.setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-mobileMask md:bg-desktopMask bg-opacity-50 md:bg-opacity-40 z-[70]" />
        <Dialog.Content className="fixed bottom-0 md:bottom-auto md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bg-white p-8 md:px-20 md:py-12 rounded-t-lg md:rounded-lg w-full md:w-fit md:max-w-1/2 md:min-w-[400px] mx-auto z-[75] outline-none focus:ring-transparent">
          {props.tittle && props.tittle.length > 0 ? (
            <Dialog.Title className="mb-4 text-xl font-bold text-center text-black font-poppins">{props.tittle}</Dialog.Title>
          ) : (
            <Dialog.Title />
          )}
          <Dialog.Description
            className="text-base text-center text-black font-poppins"
            dangerouslySetInnerHTML={{__html: props.message.replace(/(?:\r\n|\r|\n)/g, "<br />")}}
          ></Dialog.Description>
          <div className="flex justify-center mt-4">
            <button
              className="px-12 py-2 text-base text-white rounded-lg bg-mooimom font-poppins"
              onClick={() => props.action ? props.action() : props.setOpen(false)}
            >
              Ok
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default AlertDialog;
