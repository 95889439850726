import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { ResultState, initResult } from "./initialState";
import { CalculatedResultItemProps } from "@/components/Cart/constants";

const hashCode = process.env.NEXT_PUBLIC_HASH_CODE;
if (!hashCode) {
  throw new Error("NEXT_PUBLIC_HASH_CODE environment variable is not set");
}

// Function to encrypt result information
const encryptResult = (resultData: CalculatedResultItemProps) => {
  return CryptoJS.AES.encrypt(JSON.stringify(resultData), hashCode).toString();
};

// Function to decrypt result information
const decryptResult = (encryptedResult: string) => {
  try {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedResult, hashCode).toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Failed to decrypt result information", error);
    return initResult;
  }
};

export const useResultStore = create<ResultState>()(
  persist<ResultState>(
    (set, get) => ({
      resultData: encryptResult(initResult),
      updateResult: (resultData: CalculatedResultItemProps) => {
        set(
          produce((draft: ResultState) => {
            draft.resultData = encryptResult(resultData);
          })
        );
      },
      getResult: () => {
        const state = get();

        if (typeof state.resultData === "object") {
          return state.resultData;
        } else if (typeof state.resultData === "string") {
          return decryptResult(state.resultData);
        } else {
          return initResult;
        }
      }
    }), {
      name: "resultData"
    } as PersistOptions<ResultState>
  )
);
