import { useContext, useEffect, useState } from 'react';
import Image from 'next/image';
// import BrandName from './BrandName';
import CartProduct from './CartProduct';
import { useCartsStore } from '@/stores/useCartsStore';
import axios from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import AddToCart from '../Product/AddToCart';
import { AddToCartProductItemProps, CustomVariantItemProps, MinProductItemProps, ProductVariantItemProps } from '../Product/constants';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { fetchCarts, get_sales_price, getCalculatedResult, getNewToken } from '@/lib/Definitions';
import { useResultStore } from '@/stores/useResultStore';
import { useAttributesStore } from '@/stores/useAttributesStore';
import { initResult, initUser } from '@/stores/initialState';
import { event } from 'nextjs-google-analytics';
import ActionDialog from '../Utils/ActionDialog';
import { CartProductItemProps } from './constants';
import Cookies from 'js-cookie';
import { useRecommendStore } from "@/stores/useRecommendStore";
import Recommend from '../Product/Recommend';
import { GlobalContext, GlobalState } from '@/context/useGlobalContext';

import "swiper/css";


interface CartProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const Cart = (props: CartProps) => {
  const { isVisible, setIsVisible } = props;
  const viewport = Cookies.get('viewport');
  const router = useRouter();
  const { showCart, setShowCart } = useContext(GlobalContext) as GlobalState;
  const { attributes, getAttributes } = useAttributesStore();
  const [isOpen, setIsOpen] = useState(isVisible);
  const [variant, setVariant] = useState<ProductVariantItemProps | null>(null);
  const { carts, getCarts, updateCarts } = useCartsStore();
  const { userInfo, getUser, updateUser } = useUserStore();
  const { updateResult, getResult } = useResultStore();
  const [userData, setUserData] = useState(getUser());
  const [cartsData, setCartsData] = useState(getCarts());
  const [calResultData, setCalResultData] = useState(getResult());
  const [attributesData, setAttributesData] = useState(getAttributes());
  const [open, setOpen] = useState(false);
  const [addToCartProp, setAddToCartProp] = useState<AddToCartProductItemProps | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<ProductVariantItemProps | null>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeCartId, setRemoveCartId] = useState(0);
  const { recommend, getRecommend } = useRecommendStore();
  const [recommendData, setRecommendData] = useState(getRecommend());
  const [selectProduct, setSelectProduct] = useState<MinProductItemProps | null>(null);
  const [relatedOpen, setRelatedOpen] = useState(false);
  const [relatedVariant, setRelatedVariant] = useState<ProductVariantItemProps | null>(null);
  const [allChecked, setAllChecked] = useState(true);
  const [selectedCustomVariant, setSelectedCustomVariant] = useState<CustomVariantItemProps | null>(null);
  const [fromName, setFromName] = useState<string>('');
  const [toName, setToName] = useState<string>('');
  const [toEmail, setToEmail] = useState<string>('');
  const [toMobile, setToMobile] = useState<string>('');
  const [toMessage, setToMessage] = useState<string>('');
  const [pregnancy, setPregnancy] = useState<string>('');
  const [duDate, setDuDate] = useState<Date | null>(null);
  const [childName, setChildName] = useState<string>('');
  const [childAge, setChildAge] = useState<number | null>(null);
  const [childGender, setChildGender] = useState<string>('');
  const [affiliate, setAffiliate] = useState<string | undefined>(undefined);
  // const [brandList, setBrandList] = useState<Array<BrandItemProps>>([]);

  // let brand_list: Array<BrandItemProps> = [];

  useEffect(() => {
    setIsOpen(isVisible)
    setAffiliate(Cookies.get(window.btoa("affiliate")));
  }, [setIsOpen, isVisible])

  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.removeProperty('overflow'));
  }, [isOpen])

  const confirm_remove_cart = (cart_id: number) => {
    setRemoveCartId(cart_id);
    setConfirmOpen(true);
  }

  useEffect(() => {
    if (selectProduct !== null && relatedVariant === null) {
      setRelatedVariant(selectProduct.product_variant_list[0]);
    }

    if (selectProduct !== null && !relatedOpen) {
      setRelatedOpen(true);
    }
  }, [selectProduct, relatedOpen, relatedVariant])

  useEffect(() => {
    if (!relatedOpen) {
      setSelectProduct(null);
      setRelatedVariant(null);
    }
  }, [relatedOpen])

  // useEffect(() => {
  //   if (cartsData.data.length > 0) {
  //     const updatedBrandList: Array<BrandItemProps> = [];

  //     cartsData.data.forEach((cart) => {
  //       const brandName = cart.brand;
  //       const brandItem = brandList.find((brand) => brand.brand_name === brandName);
  //       if (!brandItem) {
  //         updatedBrandList.push({ brand_name: brandName, checked: true });
  //       } else {
  //         updatedBrandList.push(brandItem);
  //       }
  //     });
  //     setBrandList(updatedBrandList);
  //   } else {
  //     setBrandList([]);
  //   }
  // }, [cartsData.data])

  const remove_cart = async (cart_id: number) => {
    if (cart_id > 0) {
      try {
        const response = await axios.delete(
          '/api/cart/' + cart_id,
          { headers: { Authorization: 'Bearer ' + userData.token } }
        )

        if (response.data.status === "ok") {
          const idx = cartsData.data.findIndex(cart => cart.id === cart_id);

          if (idx > -1) {
            event("remove_from_cart", {
              category: "ecommerce",
              value: variant?.sales_price,
              currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
              items: [
                {
                  item_id: cartsData.data[idx].product_code,
                  item_name: cartsData.data[idx].name,
                  item_variant: variant?.product_code,
                  brand: cartsData.data[idx].brand,
                  price: variant?.special_price !== 0 ? variant?.special_price : cartsData.data[idx].special_price,
                  quantity: cartsData.data[idx].qty,
                  image: variant?.cart_image
                }
              ]
            });

            try {
              window.fbq('track', 'RemoveFromCart', {
                content_ids: [cartsData.data[idx].product_code],
                content_name: cartsData.data[idx].name,
                content_type: 'product',
                value: (variant?.special_price ?? cartsData.data[idx].special_price) * cartsData.data[idx].qty,
                currency: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'VND',
              })
            } catch (error) { }

            try {
              window.gtag('event', 'remove_from_cart', {
                items: [
                  {
                    id: cartsData.data[idx].product_code,
                    name: cartsData.data[idx].name,
                    variant: variant?.product_code,
                    brand: cartsData.data[idx].brand,
                    quantity: cartsData.data[idx].qty,
                    price: variant?.special_price !== 0 ? variant?.special_price : cartsData.data[idx].special_price,
                  }
                ]
              })
            } catch (error) { }

            fetchCarts("Bearer " + userData.token, Cookies.get(window.btoa("affiliate")) !== undefined && Cookies.get(window.btoa("affiliate")) !== null ? window.btoa(`affiliate,${Cookies.get(window.btoa("affiliate"))}`) : "")
              .then((response) => {
                if (response !== null) {
                  response.forEach((cart) => {
                    const cc = cartsData.data.find((c) => c.id === cart.id);
                    cart.checked = cc?.checked || false;
                  });
                  cartsData.data = response;
                  cartsData.update_date = Date.now();
                  updateCarts(cartsData);
                  setCartsData(cartsData);
                }
              });
          }
        }
      } catch (error: any) {
        if (error.response.data.error === "Invalid credentials" && userData.refresh_token !== "") {
          const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid, userData.user_id);

          if (response !== "error") {
            userData.token = response.token;
            userData.refresh_token = response.refresh_token;
            updateUser(userData);
            remove_cart(cart_id);
          } else if (userData.login_type !== "guest") {
            const newUser = { ...initUser, uuid: userData.uuid };
            setUserData(newUser);
            updateUser(newUser);
            router.push('/login?redirect=' + router.asPath);
          }
        } else {
          try {
            const body = {data: {"cart_id": cart_id, type: "remove cart", error: error?.response?.data || error, user_id: userData.user_id}, timestamp: new Date().getTime()};
            await axios.post(
              "/api/report",
              {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
            )
          } catch (error) {
            console.log(error);
          }

          if (userData.login_type !== "guest") {
            // need re-login
            const newUser = { ...initUser, uuid: userData.uuid };
            setUserData(newUser);
            updateUser(newUser);
            router.push('/login?redirect=' + router.asPath);
          } else {
            // how to handle this?
          }
        }
      }
    }
  }

  const show_add_to_cart = (cart_id: number) => {
    const idx = cartsData.data.findIndex(cart => cart.id === cart_id);

    if (idx > -1) {
      const cart = cartsData.data[idx];
      const filterVariant = cart.product_variant_list.filter((variant) => variant.variant_id === cart.product_variant_id);

      if (filterVariant.length > 0) {
        setVariant(filterVariant[0]);
      }

      if (cart.custom_variant_list && cart.custom_variant_list.length > 0 && cart.customVariant !== null) {
        setSelectedCustomVariant(cart.customVariant);
      }

      setFromName(cart.fromName);
      setToName(cart.toName);
      setToEmail(cart.toEmail);
      setToMobile(cart.toMobile);
      setToMessage(cart.toMessage);
      setPregnancy(cart.pregnancy);
      setDuDate(cart.duDate);
      setChildName(cart.childName);
      setChildAge(cart.childAge);
      setChildGender(cart.childGender);

      setAddToCartProp({
        id: cart.id,
        name: cart.name,
        msrp: cart.msrp,
        sales_price: cart.sales_price,
        product_code: cart.product_code,
        qty: cart.qty,
        image: cart.image,
        special_price: cart.special_price,
        attribute_value_list: cart.whole_attribute_value_list ? cart.whole_attribute_value_list : cart.attribute_value_list,
        product_variant_list: cart.product_variant_list,
        brand: cart.brand,
        slug: cart.slug,
        style: "popup",
        variant: variant !== null && variant !== undefined ? variant : filterVariant[0],
        setVariant: setVariant,
        setShowCart: setShowCart,
        action: "update",
        open: open,
        setOpen: setOpen,
        virtual_product: cart.virtual_product,
        need_shipping: cart.need_shipping,
        custom_variant_list: cart.custom_variant_list,
        selectedCustomVariant: selectedCustomVariant,
        setSelectedCustomVariant: setSelectedCustomVariant,
        fromName: fromName,
        setFromName: setFromName,
        toName: toName,
        setToName: setToName,
        toEmail: toEmail,
        setToEmail: setToEmail,
        toMobile: toMobile,
        setToMobile: setToMobile,
        toMessage: toMessage,
        setToMessage: setToMessage,
        pregnancy: pregnancy,
        setPregnancy: setPregnancy,
        duDate: duDate,
        setDuDate: setDuDate,
        childName: childName,
        setChildName: setChildName,
        childAge: childAge,
        setChildAge: setChildAge,
        childGender: childGender,
        setChildGender: setChildGender,
        pwp_name: cart.pwp_name,
        pwp: cart.pwp,
        shared_sales_price: cart.shared_sales_price,
        type: cart.type,
      });

      setOpen(true);
    }
  }

  const update_checked = (cart_id: number, checked: boolean) => {
    cartsData.data.find(cart => cart.id === cart_id)!.checked = checked;
    updateCarts(cartsData);

    // const brandProducts = cartsData.data.filter(cart => cart.brand === cart.brand);
    // const isAllChecked = brandProducts.every(cart => cart.checked === checked);

    // if (isAllChecked) {
    //   const isBrandChecked = brandList.find(brand => brand.brand_name === cartsData.data.find(cart => cart.id === cart_id)!.brand)?.checked;

    //   if (isBrandChecked !== checked) {
    //     const updatedBrandList = brandList.map(brand => {
    //       if (brand.brand_name === cartsData.data.find(cart => cart.id === cart_id)!.brand) {
    //         return { ...brand, checked };
    //       }
    //       return brand;
    //     });

    //     setBrandList(updatedBrandList);
    //   }
    // }
  }

  const update_all_checked = (checked: boolean) => {
    const updatedCartsData = {
      ...cartsData,
      data: cartsData.data.map((cart) => {
        if (cart.checked !== checked) {
          return { ...cart, checked };
        }
        return cart;
      }),
    };

    updateCarts(updatedCartsData);
    setAllChecked(checked);
  }

  const render_push_purchase_note = () => {
    if (calResultData.discount_shipping !== null && calResultData.discount_shipping.free_shipping) {
      return (
        <div className="flex items-center justify-center py-2 space-x-1 overflow-x-auto rounded-t md:rounded bg-price">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/free-shipping-white.png"}
            alt="free shipping icon"
            quality={100}
            width={14}
            height={14}
          />
            <p className="text-xs text-white font-poppins">Yeay! Bạn đã nhận được miễn phí vận chuyển</p>
        </div>
      )
    } else if (calResultData.push_purchase_note !== null) {
      return (
        <div className="flex items-center justify-center py-2 space-x-1 overflow-x-auto rounded-t md:rounded bg-price">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/free-shipping-white.png"}
            alt="free shipping icon"
            quality={100}
            width={14}
            height={14}
          />
          <p className="text-xs text-white font-poppins" dangerouslySetInnerHTML={{ __html: calResultData.push_purchase_note }} />
        </div>
      )
    } else {
      return <></>
    }
  }

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  useEffect(() => {
    const calculate_order = async (carts: Array<CartProductItemProps>) => {
      const carts_data: any = [];

      carts.filter((cart) => cart.checked).map((cart) => {
        carts_data.push({
          cart_id: cart.id,
          product_id: cart.product_id,
          variant_id: cart.product_variant_id,
          price: cart.sales_price,
          qty: cart.qty,
        });
      });

      if (carts_data.length > 0) {
        getCalculatedResult({
          data: {
            carts: carts_data,
            category: affiliate !== null && affiliate !== undefined ? window.btoa(`affiliate,${affiliate}`) : "",
            voucher: -1
          }
        }, 'Bearer ' + userData.token)
          .then((response) => {
            if (response.status === "ok") {
              updateResult(response);
              setCalResultData(response);
            }
          })
      } else {
        updateResult(initResult);
        setCalResultData(initResult);
      }
    };

    const newCarts = getCarts();
    setCartsData(newCarts);

    if (newCarts.data.length > 0 && userData.token && userData.token.length > 0) {
      calculate_order(newCarts.data);
    }
  }, [getCarts, carts])

  useEffect(() => {
    setAttributesData(getAttributes());
  }, [getAttributes, attributes])

  useEffect(() => {
    setRecommendData(getRecommend());
  }, [getRecommend, recommend])

  const render_cart_products = () => {
    // brand_list = [];
    return cartsData.data.sort((a, b) => a.brand.localeCompare(b.brand, 'en', { sensitivity: 'base' })).map((cart, index) => {
      const gifts = calResultData.gift_list.filter((gift) => gift.related_products.cart_id === cart.id && gift.allow);

      // hide brand name
      // if (brand_list.findIndex(brand => brand.brand_name === cart.brand) === -1) {
      //   brand_list.push({ brand_name: cart.brand, checked: true });

      //   return (
      //     <div key={index}>
      //       <BrandName
      //         brand_name={cart.brand}
      //         checked={brandList.find(brand => brand.brand_name === cart.brand) ? brandList.find(brand => brand.brand_name === cart.brand)!.checked : true}
      //         setChecked={update_brand_checked}
      //         viewport={viewport}
      //       />
      //       <CartProduct
      //         id={cart.id}
      //         product_id={cart.product_id}
      //         product_code={cart.product_code}
      //         product_variant_id={cart.product_variant_id}
      //         brand={cart.brand}
      //         image={cart.image}
      //         image_alt={cart.image_alt}
      //         name={cart.name}
      //         slug={cart.slug}
      //         msrp={cart.msrp}
      //         sales_price={cart.sales_price}
      //         special_price={cart.special_price}
      //         qty={cart.qty}
      //         product_variant_list={cart.product_variant_list}
      //         attribute_value_list={cart.attribute_value_list}
      //         remove_cart={confirm_remove_cart}
      //         show_add_to_cart={show_add_to_cart}
      //         attributes={attributesData.data}
      //         checked={cart.checked}
      //         setChecked={update_checked}
      //         gifts={gifts}
      //         setSelectProduct={setSelectProduct}
      //         viewport={viewport}
      //       />
      //     </div>
      //   )
      // } else {
        return (
          <div key={index}>
            <CartProduct
              id={cart.id}
              product_id={cart.product_id}
              product_code={cart.product_code}
              product_variant_id={cart.product_variant_id}
              brand={cart.brand}
              image={cart.image}
              image_alt={cart.image_alt}
              name={cart.name}
              slug={cart.slug}
              msrp={cart.msrp}
              sales_price={cart.sales_price}
              special_price={cart.special_price}
              qty={cart.qty}
              product_variant_list={cart.product_variant_list}
              attribute_value_list={cart.attribute_value_list}
              remove_cart={confirm_remove_cart}
              show_add_to_cart={show_add_to_cart}
              attributes={attributesData.data}
              checked={cart.checked}
              setChecked={update_checked}
              gifts={gifts}
              setSelectProduct={setSelectProduct}
              viewport={viewport}
              virtual_product={cart.virtual_product}
              need_shipping={cart.need_shipping}
              custom_variant_list={cart.custom_variant_list}
              customVariant={selectedCustomVariant}
              fromName={fromName}
              toName={toName}
              toEmail={toEmail}
              toMobile={toMobile}
              toMessage={toMessage}
              pregnancy={pregnancy}
              duDate={duDate}
              childName={childName}
              childAge={childAge}
              childGender={childGender}
              pwp_name={cart.pwp_name}
              pwp={cart.pwp}
              shared_sales_price={cart.shared_sales_price}
              type={cart.type}
              affiliate={affiliate}
            />
          </div>
        )
      // }
    })
  }

  const show_sales_price = (product: MinProductItemProps) => {
    const sales_price = get_sales_price(product, null);

    if (Array.isArray(sales_price.price)) {
      return (
        <p className={`text-xs md:text-sm font-semibold text-cartProductName`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[0].toLocaleString()} ~
        </p>
      )
    } else {
      return (
        <p className={`text-xs md:text-sm font-semibold text-cartProductName`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price.toLocaleString()}
        </p>
      )
    }
  }

  return (
    <>
      <div
        className={`${isOpen ? "fixed left-0 w-screen h-screen top-0 right-0 bottom-0 bg-black opacity-30 z-[50]" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
          setIsVisible(!isVisible);
        }}
      ></div>
      {recommendData.data.length > 0 && (
        <div
          className={`hidden md:block fixed w-60 z-[53] bg-cartExtendBg top-0 h-dscreen overflow-y-auto transition-transform ease-in-out duration-300 transform inset-y-0 -right-[468px] py-8 px-6 font-poppins ${isVisible && cartsData.data.length > 0 ? '-translate-x-[936px] delay-300' : 'translate-x-0'}`}
        >
          <div className="">
            <p className="w-full text-sm font-bold text-center text-black">Có thể bạn cũng thích</p>
          </div>
          <hr className="mt-6 bg-sku" />
          {recommendData.data.map((recommend, index) => (
            <div className="mt-6" key={index}>
              <Link href={`/product/${recommend.slug}`}>
                <Image
                  src={recommend.image}
                  alt={recommend.name + " image"}
                  width={78}
                  height={78}
                  quality={100}
                  className="mx-auto"
                />
                <div className="mt-4 text-sm text-cartProductName line-clamp-1">{recommend.name}</div>
              </Link>
              <div className="flex items-center justify-between mt-1">
                {show_sales_price(recommend)}
                <button
                  className="z-10 p-1 w-fit h-fit"
                  onClick={(event) => {
                    event?.preventDefault();
                    setSelectProduct(recommend);
                  }}
                >
                  <Image
                    src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/cart-plus.png"}
                    alt="add to cart icon"
                    width={16}
                    height={16}
                    quality={100}
                    className="cursor-pointer"
                  />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      <div
        className={`fixed w-full md:w-[468px] z-[55] bg-white top-0 h-dscreen transition-transform ease-in-out duration-300 transform inset-y-0 -right-full md:-right-[468px] py-6 md:py-8 md:px-4 ${isOpen ? "-translate-x-full" : "translate-x-0"}`}
      >
        <div className={viewport === "desktop" ? "" : "container"}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/bag.png"}
                alt="cart icon"
                quality={100}
                width={24}
                height={24}
                className=""
              />
              <p className="ml-4 text-sm font-bold text-black font-poppins">{cartsData.data.length} Sản phẩm</p>
            </div>
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
              alt="close icon"
              width={14}
              height={14}
              quality={100}
              onClick={() => {
                setIsOpen(!isOpen);
                setIsVisible(!isVisible);
              }}
              className="cursor-pointer"
            />
          </div>
          <hr className="mt-4 bg-sku md:mt-6" />
        </div>
        {cartsData.data.length > 0 ? (
          <>
            <div className="overflow-y-auto cart-list-height">
              <div className={viewport === "desktop" ? "" : "container"}>
                <div className="flex items-center mt-6 space-x-6">
                  <input
                    type="checkbox"
                    className={`h-4 w-4 rounded-sm border-mooimom text-mooimom cursor-pointer focus:ring-transparent ${allChecked ? 'bg-mooimom border-transparent' : 'bg-white'}`}
                    checked={allChecked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => update_all_checked(event.target.checked)}
                  />
                    <div className="text-sm font-bold underline text-cartBrandName font-poppins">Chọn tất cả</div>
                </div>
              </div>
              <div className={`pb-4 md:pb-8 ${process.env.NEXT_PUBLIC_STYLE_OPTION === "B" ? "overflow-y-auto cart-product-height" : ""}`}>
                {render_cart_products()}
              </div>
              {recommendData.data.length > 0 && (
                <Recommend
                  recommend={recommendData.data}
                  recommend_title="Có thể bạn cũng thích"
                  recommend_icon={null}
                  recommend_title_style="justify-start"
                  setSelectProduct={setSelectProduct}
                />
              )}
            </div>
            <div className="absolute bottom-0 left-0 right-0 z-10 bg-white md:left-4 md:right-4">
              {((calResultData.push_purchase_note !== null && calResultData.push_purchase_note.length > 0) || (calResultData.discount_shipping !== null && calResultData.discount_shipping.free_shipping)) && (
                <>
                  {render_push_purchase_note()}
                </>
              )}
              <div className={`${viewport === "desktop" ? "" : "container"} grid grid-cols-2 py-4 font-poppins md:gap-4`}>
                <div className="w-full">
                  <p className="text-xs text-black md:text-sm md:font-medium">Tổng</p>
                  <p className="text-sm font-semibold leading-5 text-black md:text-xl md:font-bold md:leading-auto">{calResultData.subtotal.toLocaleString()}{process.env.NEXT_PUBLIC_CURRENCY}</p>
                </div>
                <button
                  className={`w-full p-2 text-sm font-bold leading-5 text-center rounded text-white md:text-base md:leading-auto ${cartsData.data.filter((cart) => cart.checked).length > 0 ? "bg-review" : "bg-disabledBtn"}`}
                  onClick={() => {
                    if (cartsData.data.filter((cart) => cart.checked).length > 0) {
                      if (router.pathname === "/checkout") {
                        setIsOpen(!isOpen);
                        setIsVisible(!isVisible);
                      } else {
                        router.push("/checkout");
                        setIsOpen(!isOpen);
                        setIsVisible(!isVisible);
                      }
                    }
                  }}
                  disabled={cartsData.data.filter((cart) => cart.checked).length === 0}
                >
                  Mua hàng
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full cart-product-height">
            <div className="w-full">
              <div className="flex justify-center mt-6">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/empty-cart.png"}
                  alt="empty cart icon"
                  width={120}
                  height={120}
                  quality={100}
                />
              </div>
              <p className="mt-6 text-sm font-bold text-center text-black font-poppins">Ồ, giỏ hàng vẫn còn trống!</p>
              <p className="mt-2 text-[10px] text-attributeBorder font-poppins text-center">Hãy đi khám phá thêm nhiều lựa<br />chọn sản phẩm khác nhau cho<br />Mẹ và Bé nhé.</p>
              <div className="flex justify-center w-full mt-6">
                <Link href="/product-category/best-seller">
                  <div className="px-12 py-2 text-sm font-semibold text-center text-white rounded-lg w-fit bg-mooimom font-poppins">
                    Tìm kiếm sản phẩm
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
        {addToCartProp !== null && <AddToCart
          id={addToCartProp.id}
          name={addToCartProp.name}
          msrp={addToCartProp.msrp}
          sales_price={addToCartProp.sales_price}
          product_code={addToCartProp.product_code}
          qty={addToCartProp.qty}
          image={addToCartProp.image}
          special_price={addToCartProp.special_price}
          attribute_value_list={addToCartProp.attribute_value_list}
          product_variant_list={addToCartProp.product_variant_list}
          brand={addToCartProp.brand}
          slug={addToCartProp.slug}
          style="popup"
          variant={addToCartProp.variant}
          setVariant={addToCartProp.setVariant}
          setShowCart={setShowCart}
          action="update"
          open={open}
          setOpen={setOpen}
          virtual_product={addToCartProp.virtual_product}
          need_shipping={addToCartProp.need_shipping}
          custom_variant_list={addToCartProp.custom_variant_list}
          selectedCustomVariant={selectedCustomVariant}
          setSelectedCustomVariant={setSelectedCustomVariant}
          fromName={fromName}
          setFromName={setFromName}
          toName={toName}
          setToName={setToName}
          toEmail={toEmail}
          setToEmail={setToEmail}
          toMobile={toMobile}
          setToMobile={setToMobile}
          toMessage={toMessage}
          setToMessage={setToMessage}
          pregnancy={pregnancy}
          setPregnancy={setPregnancy}
          duDate={duDate}
          setDuDate={setDuDate}
          childName={childName}
          setChildName={setChildName}
          childAge={childAge}
          setChildAge={setChildAge}
          childGender={childGender}
          setChildGender={setChildGender}
          pwp_name={addToCartProp.pwp_name}
          pwp={addToCartProp.pwp}
          shared_sales_price={addToCartProp.shared_sales_price}
          type={addToCartProp.type}
        />}
        {selectProduct !== null && (
          <AddToCart
            id={selectProduct.id}
            name={selectProduct.name}
            msrp={selectProduct.msrp}
            sales_price={selectProduct.sales_price}
            product_code={selectProduct.sku}
            qty={selectProduct.qty}
            image={selectProduct.image}
            attribute_value_list={selectProduct.attribute_value_list}
            product_variant_list={selectProduct.product_variant_list}
            special_price={selectProduct.special_price}
            brand={selectProduct.brand}
            slug={selectProduct.slug}
            style="popup"
            variant={relatedVariant}
            setVariant={setRelatedVariant}
            setShowCart={setShowCart}
            action="add"
            open={relatedOpen}
            setOpen={setRelatedOpen}
            virtual_product={selectProduct.virtual_product}
            need_shipping={selectProduct.need_shipping}
            custom_variant_list={selectProduct.custom_variant_list}
            selectedCustomVariant={selectedCustomVariant}
            setSelectedCustomVariant={setSelectedCustomVariant}
            fromName={fromName}
            setFromName={setFromName}
            toName={toName}
            setToName={setToName}
            toEmail={toEmail}
            setToEmail={setToEmail}
            toMobile={toMobile}
            setToMobile={setToMobile}
            toMessage={toMessage}
            setToMessage={setToMessage}
            pregnancy={pregnancy}
            setPregnancy={setPregnancy}
            duDate={duDate}
            setDuDate={setDuDate}
            childName={childName}
            setChildName={setChildName}
            childAge={childAge}
            setChildAge={setChildAge}
            childGender={childGender}
            setChildGender={setChildGender}
            pwp_name={selectProduct.pwp_name}
            pwp={selectProduct.pwp}
            shared_sales_price={null}
            type={null}
          />
        )}
        <ActionDialog
          open={confirmOpen}
          setOpen={setConfirmOpen}
          message="Bạn có chắc chắn muốn xóa sản phẩm này không?"
          action={() => {
            remove_cart(removeCartId)
            .finally(() => {
              setConfirmOpen(false);
            });
          }}
        />
      </div>
    </>
  )
}

export default Cart
