import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { AttributeState, Attributes, initAttributes } from "./initialState";

const hashCode = process.env.NEXT_PUBLIC_HASH_CODE;
if (!hashCode) {
  throw new Error("NEXT_PUBLIC_HASH_CODE environment variable is not set");
}

// Function to encrypt attributes information
const encryptAttributes = (attributes: Attributes) => {
  return CryptoJS.AES.encrypt(JSON.stringify(attributes), hashCode).toString();
};

// Function to decrypt attributes information
const decryptAttributes = (encryptedAttributes: string) => {
  try {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedAttributes, hashCode).toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Failed to decrypt attributes information", error);
    return initAttributes;
  }
};

export const useAttributesStore = create<AttributeState>()(
  persist<AttributeState>(
    (set, get) => ({
      attributes: encryptAttributes(initAttributes),
      updateAttributes: (attributes: Attributes) => {
        set(
          produce((draft: AttributeState) => {
            draft.attributes = encryptAttributes(attributes);
          })
        );
      },
      getAttributes: () => {
        const state = get();

        if (typeof state.attributes === "object") {
          return state.attributes;
        } else if (typeof state.attributes === "string") {
          return decryptAttributes(state.attributes);
        } else {
          return initAttributes;
        }
      }
    }), {
      name: "attributes"
    } as PersistOptions<AttributeState>
  )
);
