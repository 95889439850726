import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { SuggestSearch, SuggestSearchState, initSuggestSearch } from "./initialState";

const hashCode = process.env.NEXT_PUBLIC_HASH_CODE;
if (!hashCode) {
  throw new Error("NEXT_PUBLIC_HASH_CODE environment variable is not set");
}

// Function to encrypt suggestSearch information
const encryptSuggestSearch = (suggestSearch: SuggestSearch) => {
  return CryptoJS.AES.encrypt(JSON.stringify(suggestSearch), hashCode).toString();
};

// Function to decrypt suggestSearch information
const decryptSuggestSearch = (encryptedSuggestSearch: string) => {
  try {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedSuggestSearch, hashCode).toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Failed to decrypt suggestSearch information", error);
    return initSuggestSearch;
  }
};

export const useSuggestSearchStore = create<SuggestSearchState>()(
  persist<SuggestSearchState>(
    (set, get) => ({
      suggestSearch: encryptSuggestSearch(initSuggestSearch),
      updateSuggestSearch: (suggestSearch: SuggestSearch) => {
        set(
          produce((draft: SuggestSearchState) => {
            draft.suggestSearch = encryptSuggestSearch(suggestSearch);
          })
        );
      },
      getSuggestSearch: () => {
        const state = get();

        if (typeof state.suggestSearch === "object") {
          return state.suggestSearch;
        } else if (typeof state.suggestSearch === "string") {
          return decryptSuggestSearch(state.suggestSearch);
        } else {
          return initSuggestSearch;
        }
      }
    }), {
      name: "suggestSearch"
    } as PersistOptions<SuggestSearchState>
  )
);
