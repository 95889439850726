import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { initRecommend, Recommend, RecommendState } from "./initialState";

const hashCode = process.env.NEXT_PUBLIC_HASH_CODE;
if (!hashCode) {
  throw new Error("NEXT_PUBLIC_HASH_CODE environment variable is not set");
}

// Function to encrypt recommend information
const encryptRecommend = (recommend: Recommend) => {
  return CryptoJS.AES.encrypt(JSON.stringify(recommend), hashCode).toString();
};

// Function to decrypt recommend information
const decryptRecommend = (encryptedRecommend: string) => {
  try {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedRecommend, hashCode).toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Failed to decrypt recommend information", error);
    return initRecommend;
  }
};

export const useRecommendStore = create<RecommendState>()(
  persist<RecommendState>(
    (set, get) => ({
      recommend: encryptRecommend(initRecommend),
      updateRecommend: (recommend: Recommend) => {
        set(
          produce((draft: RecommendState) => {
            draft.recommend = encryptRecommend(recommend);
          })
        );
      },
      getRecommend: () => {
        const state = get();

        if (typeof state.recommend === "object") {
          return state.recommend;
        } else if (typeof state.recommend === "string") {
          return decryptRecommend(state.recommend);
        } else {
          return initRecommend;
        }
      }
    }), {
      name: "recommend"
    } as PersistOptions<RecommendState>
  )
);
