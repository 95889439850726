import Link from "next/link";
import Image from 'next/image';
import { CategoryItem } from "@/lib/constants";
import { useEffect, useState } from "react";
import SecondMobileMenu from "./SecondMobileMenu";
import { User } from '@/stores/initialState';
import { NextRouter } from "next/router";


interface CategoryListItemProps {
  categories: Array<CategoryItem>;
  isMenuOpen: boolean;
  userData: User;
  router: NextRouter;
}

const MobileMenuItem = (props: CategoryListItemProps) => {
  const { categories, isMenuOpen, userData, router } = props;
  const [showMenu, setShowMenu] = useState<number>(0)
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState<CategoryItem | null>(null);

  const handleShowMenu = (id: number) => {
    const cc = categories.find(category => category.id === id);

    if (cc !== undefined && cc !== null) {
      setCategory(cc);
      setIsOpen(true);
    }
  }

  useEffect(() => {
    if (!isMenuOpen) {
      setIsOpen(false);
    }
  }, [isMenuOpen])

  const specialCategories = categories.filter(category => category.type === "Special");
  const mainCategories = categories.filter(category => category.type === "A");
  const brandCategories = categories.filter(category => category.type === "BRAND");

  const render_special_categories = () => {
    return specialCategories.map((category, index) => {
      if (index === 0) {
        return (
          <div key={index} className="text-lg font-bold">{category.name}</div>
        )
      } else if (index === specialCategories.length - 1) {
        return (
          <div key={index}>
            <div className="mx-2 my-6 text-sm">
              <Link href={category.name === "Promotions" ? `/${category.slug}` :`/product-category/${category.slug}`}>
                {category.name}
              </Link>
            </div>
            {/* <div className="mx-2 my-6 text-sm">Event</div> */}
            <hr className="my-6 bg-reviewBg" />
          </div>
        )
      } else {
        return (
          <div key={index} className="mx-2 my-6 text-sm">
            <Link href={category.name === "Promotions" ? `/${category.slug}` :`/product-category/${category.slug}`}>
              {category.name}
            </Link>
          </div>
        )
      }
    })
  }

  const render_custom_categories = () => {
    return (
      <>
        <div>
          <p className="text-lg font-bold">Brands</p>
          {brandCategories.length > 0 && brandCategories[0].featured_brands.length > 0 && (
            <>
              {brandCategories[0].featured_brands.map((brand, index) => (
                <div key={index} className={index === 0 ? "mt-6" : "mt-8"}>
                  <Link href={`/product-category/brand?brand=${brand.name}`}>
                    <p className="px-2 text-sm font-normal font-poppins">{brand.name}</p>
                  </Link>
                </div>
              ))}
              <div className="mt-8"
                onClick={() => handleShowMenu(brandCategories.length > 0 ? brandCategories[0].id : 0)}
              >
                <p className="px-2 text-sm font-normal font-poppins">All Brands</p>
              </div>
            </>
          )}
        </div>
        {/* <hr className="my-6 bg-reviewBg" />
        <div className="text-lg font-bold">
          <Link href={userData.token === "" ? "/login?redirect=https://affiliate.mooimom.id" : "https://affiliate.mooimom.id?uid=" + userData.token} target="_blank">
            Program Afiliasi
          </Link>
        </div>
        <hr className="my-6 bg-reviewBg" />
        <div className="text-lg font-bold">
          <Link href="/mamapedia-homepage">Mamapedia</Link>
        </div>
        <hr className="my-6 bg-reviewBg" />
        <div className="text-lg font-bold">Membership</div>
        <div className="mx-2 my-6 text-sm">
          <Link href="/rewards">MOOIMOM Rewards</Link>
        </div>
        <div className="mx-2 my-6 text-sm">
          <Link href="/user-warranty-info">Garansi Produk</Link>
        </div> */}
      </>
    )
  }

  return (
    <div className="w-full overflow-y-auto mega-menu-height font-poppins text-mobileMenu">
      <div className="container">
        {specialCategories.length > 0 && render_special_categories()}
        {mainCategories.length > 0 && (
          <>
            <div className="text-lg font-bold">Danh mục</div>
            {mainCategories.map((category, index) => (
              <div
                key={index}
                className="flex items-center justify-between mx-2 my-6 text-sm"
                onClick={() => handleShowMenu(category.id)}
              >
                <p>{category.name}</p>
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow_right.png"}
                  alt="arrow icon"
                  width={14}
                  height={14}
                  quality={100}
                  className={`cursor-pointer`}
                />
              </div>
            ))}
            <hr className="my-6 bg-reviewBg" />
          </>
        )}
        {render_custom_categories()}
        <SecondMobileMenu isOpen={isOpen} setIsOpen={setIsOpen} category={category} type={category?.type || "A"} />
      </div>
    </div>
  )
}

export default MobileMenuItem
