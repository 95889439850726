import { useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import * as Dialog from '@radix-ui/react-dialog';
import { usePopupStore } from '@/stores/usePopupStore';
import { PopupInfo, PopupItem } from '@/stores/initialState';
import { fetchPopup } from '@/lib/Definitions';
import { NextRouter } from 'next/router';
import { event } from 'nextjs-google-analytics';
import { GlobalContext, GlobalState } from '@/context/useGlobalContext';


interface PopupProps {
  token: string;
  path: string;
  router: NextRouter;
}

const noShowUrls = [
  "/login",
  "/signup",
  "/password",
  "/reset-password",
  "/checkout",
  "/thankyou",
  "/unsubscribe",
  "/user",
  "/user-warranty-info",
  "/user-warranty-status",
  "/user-warranty-claim",
  "/user-warranty-register",
  "/user-warranty-registered",
  "/user-warranty-terms-conditions",
  "/user-member",
  "/user-purchase",
  "/user-profile",
  "/contact-us",
]

const Popup = (props: PopupProps) => {
  const { isPopup, setIsPopup } = useContext(GlobalContext) as GlobalState;
  const { getPopup, updatePopup } = usePopupStore();
  const [popupData, setPopupData] = useState<PopupInfo>(getPopup());
  const [open, setOpen] = useState(false);
  const [popupInfo, setPopupInfo] = useState<PopupItem | null>(null);
  const [isHandled, setIsHandled] = useState(false);

  useEffect(() => {
    if (popupData.update_date === null || (popupData.update_date < Date.now() - 600000 && popupData.data.info.length === 0) || (popupData.update_date < Date.now() - 86400000 && popupData.data.info.length > 0)) {
      fetchPopup("Bearer " + props.token)
      .then((data) => {
        popupData.update_date = Date.now();

        if (data.length > 0) {
          popupData.data.info = data;
        }

        updatePopup(popupData);
        setPopupData(popupData);
      });
    }
  }, [])

  useEffect(() => {
    if (!isPopup && popupData.data.info.length > 0) {
      const popupInfoList = popupData.data.info.filter((item) => item.match_url === props.path || (item.match_url === null && !noShowUrls.includes(props.path)));
      const validPopupInfoList = popupInfoList.filter((info) => {
        const record = popupData.data.record.find((record) => record.popup_id === info.id);
        return (record && record.popup_count < info.max_times) || record === undefined;
      });

      if (validPopupInfoList.length > 0) {
        setPopupInfo(validPopupInfoList[0]);
      }
    }
  }, [popupData])

  useEffect(() => {
    let timer = null;
    let scroll = false;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (!isPopup && popupInfo !== null && popupInfo.scroll_depth !== null && scrollPosition / (documentHeight - windowHeight) > popupInfo.scroll_depth / 100) {
        setOpen(true);
      }
    };

    if (popupInfo !== null) {
      if (!isPopup && popupInfo.type === "wait" && popupInfo.wait_seconds !== null) {
        timer = setTimeout(() => {
          setOpen(true);
        }, popupInfo.wait_seconds * 1000);
      } else if (popupInfo.type === "scroll" && popupInfo.scroll_depth !== null) {
        scroll = true;
        window.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }

      if (scroll) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [popupInfo]);

  useEffect(() => {
    if (open && popupInfo !== null) {
      setIsPopup(true);

      const record = popupData.data.record.find((record) => record.popup_id === popupInfo.id);

      if (record) {
        record.popup_count += 1;
      } else {
        popupData.data.record.push({
          popup_id: popupInfo.id,
          popup_count: 1
        });
      }

      updatePopup(popupData);

      event("popup_open", {
        event_category: popupInfo.name,
        event_label: props.router.asPath,
      });
    }
  }, [open])

  const handleClick = (action: string) => {
    if (popupInfo !== null) {
      if (!isHandled) {
        event("popup_" + action, {
          event_category: popupInfo.name,
          event_label: props.router.asPath,
        });
      }

      setIsHandled(true);

      if (action === "link" && popupInfo.link !== null) {
        props.router.push(popupInfo.link);
      }
    }
  }

  if (popupData.data.info.length === 0 || popupInfo === null || !open || (isPopup && isHandled)) return null;

  return (
    <Dialog.Root open={open} onOpenChange={(isOpen) => {
      setOpen(isOpen);
      if (!isOpen) {
        handleClick("close");
      }
    }}>
      <Dialog.Overlay className="fixed inset-0 bg-mobileMask md:bg-desktopMask bg-opacity-50 md:bg-opacity-40 z-[70]" />
      <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-4/5 md:w-fit md:max-w-1/2 md:min-w-[400px] mx-auto z-[75] outline-none focus:ring-transparent">
        <Dialog.Title />
        <Dialog.Description>
          <div className="relative">
            <Image
              src={popupInfo.desktop_image}
              alt={popupInfo.name}
              width={800}
              height={400}
              className={`hidden md:block ${popupInfo.link ? "cursor-pointer" : ""}`}
              onClick={() => popupInfo.link ? handleClick("link") : ""}
            />
            <Image
              src={popupInfo.mobile_image}
              alt={popupInfo.name}
              width={800}
              height={400}
              className={`md:hidden ${popupInfo.link ? "cursor-pointer" : ""}`}
              onClick={() => popupInfo.link ? handleClick("link") : ""}
            />
            <Dialog.Close className="absolute p-1 top-1 right-1">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
                alt="close icon"
                width={16}
                height={16}
                quality={100}
              />
            </Dialog.Close>
          </div>
        </Dialog.Description>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default Popup;
