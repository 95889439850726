import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { Categories, CategoryState, initCategories } from "./initialState";

const hashCode = process.env.NEXT_PUBLIC_HASH_CODE;
if (!hashCode) {
  throw new Error("NEXT_PUBLIC_HASH_CODE environment variable is not set");
}

// Function to encrypt categories information
const encryptCategories = (categories: Categories) => {
  return CryptoJS.AES.encrypt(JSON.stringify(categories), hashCode).toString();
};

// Function to decrypt categories information
const decryptCategories = (encryptedCategories: string) => {
  try {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedCategories, hashCode).toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Failed to decrypt categories information", error);
    return initCategories;
  }
};

export const useCategoriesStore = create<CategoryState>()(
  persist(
    (set, get) => ({
      categories: encryptCategories(initCategories),
      updateCategories: (categories: Categories) => {
        set(
          produce((draft: CategoryState) => {
            draft.categories = encryptCategories(categories);
          })
        );
      },
      getCategories: () => {
        const state = get();

        if (typeof state.categories === "object") {
          return state.categories;
        } else if (typeof state.categories === "string") {
          return decryptCategories(state.categories);
        } else {
          return initCategories;
        }
      }
    }), {
      name: "categories"
    } as PersistOptions<CategoryState>
  )
);
