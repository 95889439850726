import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { initPopup, PopupInfo, PopupState } from "./initialState";

const hashCode = process.env.NEXT_PUBLIC_HASH_CODE;
if (!hashCode) {
  throw new Error("NEXT_PUBLIC_HASH_CODE environment variable is not set");
}

// Function to encrypt popup information
const encryptPopup = (popup: PopupInfo) => {
  return CryptoJS.AES.encrypt(JSON.stringify(popup), hashCode).toString();
};

// Function to decrypt popup information
const decryptPopup = (encryptedPopup: string) => {
  try {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedPopup, hashCode).toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Failed to decrypt popup information", error);
    return initPopup;
  }
};

export const usePopupStore = create<PopupState>()(
  persist<PopupState>(
    (set, get) => ({
      popup: encryptPopup(initPopup),
      updatePopup: (popup: PopupInfo) => {
        set(
          produce((draft: PopupState) => {
            draft.popup = encryptPopup(popup);
          })
        );
      },
      getPopup: () => {
        const state = get();

        if (typeof state.popup === "object") {
          return state.popup;
        } else if (typeof state.popup === "string") {
          return decryptPopup(state.popup);
        } else {
          return initPopup;
        }
      }
    }), {
      name: "popup"
    } as PersistOptions<PopupState>
  )
);
